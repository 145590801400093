import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import ViewMasterComponent from '../components/ViewMasterComponent';
import maskC from '../images/viewmaster/mask_c.png';
import maskSlide from '../images/viewmaster/mask_slide_new.jpg';
import highlight from '../images/viewmaster/highlight.png';
import siteLogo from '../images/viewmaster/logo.png';
import { fixPlaceholderResize } from '../utils/functions';

const RightWrapper = styled.div`
  height: calc(100% - 3.5rem);
  width: 100%;
  float: right;
  background-color: black;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
  padding-top: 3.5rem;
  @media (min-width: ${props => props.theme.breakpoint.l}) {
    padding-top: 0rem;
  }
`;

const vmSettings = {
  transitionOut: 0.45,
  transitionIn: 0.45,
  overlay: highlight,
  lens_mask: maskC,
  slide_mask: maskSlide,
  logo: siteLogo,
  idle: 3,
  motionBlur: 20,
};

function generateSlides(edge, placeHolder) {
  try {
    // console.log(edge.node.data.title_wordmark.localFile.childImageSharp.resize.src);
    const slide = {
      picture: fixPlaceholderResize(edge.node.data, placeHolder),
      url: edge.node.uid,
      title: edge.node.data.title.text,
      wordmark: edge.node.data.title_wordmark.localFile.childImageSharp.resize.src,
      wordmark_width: edge.node.data.title_wordmark.localFile.childImageSharp.resize.width,
      wordmark_height: edge.node.data.title_wordmark.localFile.childImageSharp.resize.height,
      wordmark_aspect_ratio: edge.node.data.title_wordmark.localFile.childImageSharp.resize.aspectRatio,
      hue: 0,
    };
    return slide;
  } catch (error) {
    const slide = {
      picture: fixPlaceholderResize(edge.node.data, placeHolder),
      url: edge.node.uid,
      title: edge.node.data.title.text,
      wordmark: undefined ? edge.node.data.title_wordmark.localFile.childImageSharp.resize.src : '',
      wordmark_width: undefined ? edge.node.data.title_wordmark.localFile.childImageSharp.resize.width : 0,
      wordmark_height: undefined ? edge.node.data.title_wordmark.localFile.childImageSharp.resize.height : 0,
      wordmark_aspect_ratio: undefined ? edge.node.data.title_wordmark.localFile.childImageSharp.resize.aspectRatio : 0,
      hue: 0,
    };
    return slide;
  }
}

const IndexPage = ({
  data: {
    // eslint-disable-next-line no-unused-vars
    projects: { edges },
    placeHolder,
  },
}) => {
  // console.log(placeHolder)
  
  return (
    <Layout isIndex>
      <Helmet>
        <title>Kid Sister - Home</title>
      </Helmet>
      <RightWrapper>
        <ViewMasterComponent
          transitionOut={vmSettings.transitionOut}
          transitionIn={vmSettings.transitionIn}
          overlay={vmSettings.overlay}
          lens_mask={vmSettings.lens_mask}
          slide_mask={vmSettings.slide_mask}
          slides={edges.map(generateSlides, placeHolder)}
          logo={vmSettings.logo}
          idle={vmSettings.idle}
          wordmark={vmSettings.press_wordmark}
          motionBlur={vmSettings.motionBlur}
          style={{ width: '100%', height: '100%' }}
        />
      </RightWrapper>
    </Layout>
  )
};

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    projects: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
    placeHolder: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    projects: allPrismicProject(
      sort: { fields: [data___taxonomy], order: [ASC] }
      filter: { data: { featured: { eq: "yes" } } }
    ) {
      edges {
        node {
          uid
          data {
            taxonomy
            hero_image {
              localFile {
                childImageSharp {
                  resize(width: 1000, quality: 90) {
                    src
                    aspectRatio
                  }
                }
              }
            }
            title_wordmark {
              localFile {
                childImageSharp {
                  resize(width: 900, quality: 90) {
                    src
                    height
                    width
                    aspectRatio
                  }
                }
              }
            }
            title {
              text
            }
            editor
          }
        }
      }
    }
    placeHolder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        resize(width: 900, quality: 85) {
          src
        }
      }
    }
  }
`;
