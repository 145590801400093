(function ($hx_exports, $global) {
	"use strict";

	// console.log(typeof window);

	if (typeof window === 'undefined') {
		// console.log('Breaking');
		return;
	}

	// console.log('Continuing because we didnt break.');

	const PIXI = require('pixi.js');

	var $estr = function () { return js_Boot.__string_rec(this, ''); }, $hxEnums = $hxEnums || {}, $_;
	function $extend(from, fields) {
		var proto = Object.create(from);
		for (var name in fields) proto[name] = fields[name];
		if (fields.toString !== Object.prototype.toString) proto.toString = fields.toString;
		return proto;
	}
	var EReg = function (r, opt) {
		this.r = new RegExp(r, opt.split("u").join(""));
	};
	EReg.__name__ = true;
	EReg.prototype = {
		match: function (s) {
			if (this.r.global) {
				this.r.lastIndex = 0;
			}
			this.r.m = this.r.exec(s);
			this.r.s = s;
			return this.r.m != null;
		}
	};
	var HxOverrides = function () { };
	HxOverrides.__name__ = true;
	HxOverrides.iter = function (a) {
		return {
			cur: 0, arr: a, hasNext: function () {
				return this.cur < this.arr.length;
			}, next: function () {
				return this.arr[this.cur++];
			}
		};
	};
	var Lambda = function () { };
	Lambda.__name__ = true;
	Lambda.exists = function (it, f) {
		var x = $getIterator(it);
		while (x.hasNext()) if (f(x.next())) {
			return true;
		}
		return false;
	};
	Math.__name__ = true;
	var Perf = $hx_exports["Perf"] = function (pos, offset) {
		if (offset == null) {
			offset = 0;
		}
		if (pos == null) {
			pos = "TR";
		}
		this._perfObj = window.performance;
		if (Reflect.field(this._perfObj, "memory") != null) {
			this._memoryObj = Reflect.field(this._perfObj, "memory");
		}
		this._memCheck = this._perfObj != null && this._memoryObj != null && this._memoryObj.totalJSHeapSize > 0;
		this._pos = pos;
		this._offset = offset;
		this.currentFps = 60;
		this.currentMs = 0;
		this.currentMem = "0";
		this.lowFps = 60;
		this.avgFps = 60;
		this._measureCount = 0;
		this._totalFps = 0;
		this._time = 0;
		this._ticks = 0;
		this._fpsMin = 60;
		this._fpsMax = 60;
		this._startTime = this._perfObj != null && this._perfObj.now != null ? this._perfObj.now() : new Date().getTime();
		this._prevTime = -Perf.MEASUREMENT_INTERVAL;
		this._createFpsDom();
		this._createMsDom();
		if (this._memCheck) {
			this._createMemoryDom();
		}
		if (window.requestAnimationFrame != null) {
			this.RAF = ($_ = window, $bind($_, $_.requestAnimationFrame));
		} else if (window.mozRequestAnimationFrame != null) {
			this.RAF = window.mozRequestAnimationFrame;
		} else if (window.webkitRequestAnimationFrame != null) {
			this.RAF = window.webkitRequestAnimationFrame;
		} else if (window.msRequestAnimationFrame != null) {
			this.RAF = window.msRequestAnimationFrame;
		}
		if (window.cancelAnimationFrame != null) {
			this.CAF = ($_ = window, $bind($_, $_.cancelAnimationFrame));
		} else if (window.mozCancelAnimationFrame != null) {
			this.CAF = window.mozCancelAnimationFrame;
		} else if (window.webkitCancelAnimationFrame != null) {
			this.CAF = window.webkitCancelAnimationFrame;
		} else if (window.msCancelAnimationFrame != null) {
			this.CAF = window.msCancelAnimationFrame;
		}
		if (this.RAF != null) {
			this._raf = this.RAF.apply(window, [$bind(this, this._tick)]);
		}
	};
	Perf.__name__ = true;
	Perf.prototype = {
		_init: function () {
			this.currentFps = 60;
			this.currentMs = 0;
			this.currentMem = "0";
			this.lowFps = 60;
			this.avgFps = 60;
			this._measureCount = 0;
			this._totalFps = 0;
			this._time = 0;
			this._ticks = 0;
			this._fpsMin = 60;
			this._fpsMax = 60;
			this._startTime = this._perfObj != null && this._perfObj.now != null ? this._perfObj.now() : new Date().getTime();
			this._prevTime = -Perf.MEASUREMENT_INTERVAL;
		}
		, _now: function () {
			if (this._perfObj != null && this._perfObj.now != null) {
				return this._perfObj.now();
			} else {
				return new Date().getTime();
			}
		}
		, _tick: function (val) {
			var time = this._perfObj != null && this._perfObj.now != null ? this._perfObj.now() : new Date().getTime();
			this._ticks++;
			if (this._raf != null && time > this._prevTime + Perf.MEASUREMENT_INTERVAL) {
				this.currentMs = Math.round(time - this._startTime);
				this.ms.innerHTML = "MS: " + this.currentMs;
				this.currentFps = Math.round(this._ticks * 1000 / (time - this._prevTime));
				if (this.currentFps > 0 && val > Perf.DELAY_TIME) {
					this._measureCount++;
					this._totalFps += this.currentFps;
					this.lowFps = this._fpsMin = Math.min(this._fpsMin, this.currentFps);
					this._fpsMax = Math.max(this._fpsMax, this.currentFps);
					this.avgFps = Math.round(this._totalFps / this._measureCount);
				}
				this.fps.innerHTML = "FPS: " + this.currentFps + " (" + this._fpsMin + "-" + this._fpsMax + ")";
				if (this.currentFps >= 30) {
					this.fps.style.backgroundColor = Perf.FPS_BG_CLR;
				} else if (this.currentFps >= 15) {
					this.fps.style.backgroundColor = Perf.FPS_WARN_BG_CLR;
				} else {
					this.fps.style.backgroundColor = Perf.FPS_PROB_BG_CLR;
				}
				this._prevTime = time;
				this._ticks = 0;
				if (this._memCheck) {
					this.currentMem = this._getFormattedSize(this._memoryObj.usedJSHeapSize, 2);
					this.memory.innerHTML = "MEM: " + this.currentMem;
				}
			}
			this._startTime = time;
			if (this._raf != null) {
				this._raf = this.RAF.apply(window, [$bind(this, this._tick)]);
			}
		}
		, _createDiv: function (id, top) {
			if (top == null) {
				top = 0;
			}
			var div = window.document.createElement("div");
			div.id = id;
			div.className = id;
			div.style.position = "absolute";
			switch (this._pos) {
				case "BL":
					div.style.left = this._offset + "px";
					div.style.bottom = (this._memCheck ? 48 : 32) - top + "px";
					break;
				case "BR":
					div.style.right = this._offset + "px";
					div.style.bottom = (this._memCheck ? 48 : 32) - top + "px";
					break;
				case "TL":
					div.style.left = this._offset + "px";
					div.style.top = top + "px";
					break;
				case "TR":
					div.style.right = this._offset + "px";
					div.style.top = top + "px";
					break;
			}
			div.style.width = "80px";
			div.style.height = "12px";
			div.style.lineHeight = "12px";
			div.style.padding = "2px";
			div.style.fontFamily = Perf.FONT_FAMILY;
			div.style.fontSize = "9px";
			div.style.fontWeight = "bold";
			div.style.textAlign = "center";
			window.document.body.appendChild(div);
			return div;
		}
		, _createFpsDom: function () {
			this.fps = this._createDiv("fps");
			this.fps.style.backgroundColor = Perf.FPS_BG_CLR;
			this.fps.style.zIndex = "995";
			this.fps.style.color = Perf.FPS_TXT_CLR;
			this.fps.innerHTML = "FPS: 0";
		}
		, _createMsDom: function () {
			this.ms = this._createDiv("ms", 16);
			this.ms.style.backgroundColor = Perf.MS_BG_CLR;
			this.ms.style.zIndex = "996";
			this.ms.style.color = Perf.MS_TXT_CLR;
			this.ms.innerHTML = "MS: 0";
		}
		, _createMemoryDom: function () {
			this.memory = this._createDiv("memory", 32);
			this.memory.style.backgroundColor = Perf.MEM_BG_CLR;
			this.memory.style.color = Perf.MEM_TXT_CLR;
			this.memory.style.zIndex = "997";
			this.memory.innerHTML = "MEM: 0";
		}
		, _getFormattedSize: function (bytes, frac) {
			if (frac == null) {
				frac = 0;
			}
			if (bytes == 0) {
				return "0";
			}
			var precision = Math.pow(10, frac);
			var i = Math.floor(Math.log(bytes) / Math.log(1024));
			return Math.round(bytes * precision / Math.pow(1024, i)) / precision + " " + ["Bytes", "KB", "MB", "GB", "TB"][i];
		}
		, addInfo: function (val) {
			this.info = this._createDiv("info", this._memCheck ? 48 : 32);
			this.info.style.backgroundColor = Perf.INFO_BG_CLR;
			this.info.style.color = Perf.INFO_TXT_CLR;
			this.info.style.zIndex = "998";
			this.info.innerHTML = val;
		}
		, clearInfo: function () {
			if (this.info != null) {
				window.document.body.removeChild(this.info);
				this.info = null;
			}
		}
		, destroy: function () {
			this.CAF.apply(window, [this._raf]);
			this._raf = null;
			this._perfObj = null;
			this._memoryObj = null;
			if (this.fps != null) {
				window.document.body.removeChild(this.fps);
				this.fps = null;
			}
			if (this.ms != null) {
				window.document.body.removeChild(this.ms);
				this.ms = null;
			}
			if (this.memory != null) {
				window.document.body.removeChild(this.memory);
				this.memory = null;
			}
			this.clearInfo();
			this.currentFps = 60;
			this.currentMs = 0;
			this.currentMem = "0";
			this.lowFps = 60;
			this.avgFps = 60;
			this._measureCount = 0;
			this._totalFps = 0;
			this._time = 0;
			this._ticks = 0;
			this._fpsMin = 60;
			this._fpsMax = 120;
			this._startTime = this._perfObj != null && this._perfObj.now != null ? this._perfObj.now() : new Date().getTime();
			this._prevTime = -Perf.MEASUREMENT_INTERVAL;
		}
		, _cancelRAF: function () {
			this.CAF.apply(window, [this._raf]);
			this._raf = null;
		}
	};
	var PreRender = function (maskPath, size) {
		this.picLoaded = false;
		this.maskLoaded = false;
		var _gthis = this;
		this.size = size;
		this.canv = window.document.createElement("canvas");
		this.canv.width = size;
		this.canv.height = size;
		this.ctx = this.canv.getContext("2d", null);
		this.ctx.imageSmoothingEnabled = true;
		this.mask = new Image();
		this.mask.onload = function () {
			_gthis.maskLoaded = true;
			if (_gthis.picLoaded == true) {
				_gthis.draw();
			}
		};
		this.mask.onerror = function () {
			console.log("src/PreRender.hx:56:", "error: mask image not found");
		};
		this.mask.src = maskPath;
	};
	PreRender.__name__ = true;
	PreRender.prototype = {
		load: function (path) {
			var _gthis = this;
			this.picture = new Image();
			this.picture.onload = function () {
				_gthis.picLoaded = true;
				if (_gthis.maskLoaded == true) {
					_gthis.draw();
				}
			};
			this.picture.src = path;
		}
		, draw: function () {
			// console.log("src/PreRender.hx:76:", "DRAW!");s
			var sx = 0;
			var sy = 0;
			var sw = 0;
			var sh = 0;
			if (this.picture.width < this.picture.height) {
				sy = (this.picture.height - this.picture.width) / 2;
				sw = this.picture.width;
				sh = this.picture.width;
			} else {
				sx = (this.picture.width - this.picture.height) / 2;
				sw = this.picture.height;
				sh = this.picture.height;
			}
			this.ctx.drawImage(this.picture, sx, sy, sw, sh, 0, 0, this.size, this.size);
			this.ctx.globalCompositeOperation = "multiply";
			this.ctx.drawImage(this.mask, 0, 0, this.mask.width, this.mask.height, 0, 0, this.size, this.size);
			if (this.onReady != null) {
				this.onReady();
			}
		}
	};
	var Reflect = function () { };
	Reflect.__name__ = true;
	Reflect.field = function (o, field) {
		try {
			return o[field];
		} catch (e) {
			return null;
		}
	};
	Reflect.isFunction = function (f) {
		if (typeof (f) == "function") {
			return !(f.__name__ || f.__ename__);
		} else {
			return false;
		}
	};
	Reflect.compareMethods = function (f1, f2) {
		if (f1 == f2) {
			return true;
		}
		if (!Reflect.isFunction(f1) || !Reflect.isFunction(f2)) {
			return false;
		}
		if (f1.scope == f2.scope && f1.method == f2.method) {
			return f1.method != null;
		} else {
			return false;
		}
	};
	var Std = function () { };
	Std.__name__ = true;
	Std.string = function (s) {
		return js_Boot.__string_rec(s, "");
	};
	var VMPixiContainer = function () {
		this._animationFrameId = null;
		this.pixelRatio = 1;
		this.autoResize = true;
		this.transparent = false;
		this.antialias = true;
		this.forceFXAA = true
		this.roundPixels = false;
		this.autoDensity = true;
		this.legacy = false;
		this.clearBeforeRender = true;
		this.preserveDrawingBuffer = false;
		this.backgroundColor = 0;
		this.width = window.innerWidth;
		this.height = window.innerHeight;
		this.position = "static";
	};
	VMPixiContainer.__name__ = true;
	VMPixiContainer.prototype = {
		start: function (rendererType, parentDom, canvasElement) {
			if (rendererType == null) {
				rendererType = "auto";
			}
			if (canvasElement == null) {
				this.canvas = window.document.createElement("canvas");
				this.canvas.style.width = this.width + "px";
				this.canvas.style.height = this.height + "px";
				this.canvas.style.position = this.position;
			} else {
				this.canvas = canvasElement;
			}
			if (this.autoResize) {
				window.onresize = $bind(this, this._onWindowResize);
			}
			var renderingOptions = {};
			renderingOptions.width = this.width | 0;
			renderingOptions.height = this.height | 0;
			renderingOptions.view = this.canvas;
			renderingOptions.backgroundColor = this.backgroundColor;
			renderingOptions.resolution = this.pixelRatio;
			renderingOptions.antialias = this.antialias;
			renderingOptions.forceFXAA = this.forceFXAA;
			renderingOptions.autoResize = this.autoResize;
			renderingOptions.transparent = this.transparent;
			renderingOptions.clearBeforeRender = this.clearBeforeRender;
			renderingOptions.preserveDrawingBuffer = this.preserveDrawingBuffer;
			renderingOptions.roundPixels = this.roundPixels;
			renderingOptions.legacy = this.legacy;
			renderingOptions.autoDensity = this.autoDensity;

			if (rendererType == null) {
				this.app = new PIXI.Application(renderingOptions);
			} else if (rendererType == "canvas") {
				renderingOptions.forceCanvas = true;
				this.app = new PIXI.Application(renderingOptions);
			} else {
				this.app = new PIXI.Application(renderingOptions);
			}
			this.stage = this.app.stage;
			this.renderer = this.app.renderer;
			if (parentDom == null) {
				window.document.body.appendChild(this.app.view);
			} else {
				parentDom.appendChild(this.app.view);
			}
			this.app.ticker.add($bind(this, this._onRequestAnimationFrame));
		}
		, _onWindowResize: function (event) {
			this.width = window.innerWidth;
			this.height = 'calc(100%)';
			this.app.renderer.resize(this.width, this.height);
			this.canvas.style.width = this.width + "px";
			this.canvas.style.height = this.height + "px";
			if (this.onResize != null) {
				this.onResize();
			}
		}
		, _onRequestAnimationFrame: function () {
			if (this.onUpdate != null) {
				this.onUpdate(this.app.ticker.deltaTime);
			}
		}
	};
	var ViewMaster = $hx_exports["ViewMaster"] = function (props, conf, container, id, onLoad) {
		var _gthis = this;
		this.container = container;
		if (this.container == null) {
			this.container = window.document.getElementById(id);
		}

		this.canvas = window.document.createElement("canvas");
		this.pxcontainer = new VMPixiContainer();
		this.container.addEventListener("click", function () {
			_gthis.redirectToCurProject();
		});
		this.container.addEventListener("touchend", function () {
			_gthis.redirectToCurProject();
		});
		this.slides = [];
		if (props != null) {
			this.configLoaded(props);
			return;
		}
		if (conf != null) {
			var req = new haxe_http_HttpJs("js/vmconfig.json");
			req.onData = function (d) {
				var cnf = JSON.parse(d);
				if (cnf != null) {
					_gthis.configLoaded(cnf);
				} else {
					window.alert(Std.string("inavlid config at \"" + req.url + "\""));
				}
			};
			req.onError = function (msg) {
				console.log("src/ViewMaster.hx:76:", msg);
			};
			req.request();
		}
	};
	ViewMaster.__name__ = true;
	ViewMaster.prototype = {
		configLoaded: function (conf) {
			this.config = conf;
			var _g = 0;
			var _g1 = this.config.slides;
			while (_g < _g1.length) {
				var sceneslide = new SceneSlide(_g1[_g++], this.config);
				this.slides.push(sceneslide);
			}
			if (this.slides.length > 0) {
				this.currentSlide = this.slides[0];
			} else {
				console.log("src/ViewMaster.hx:93:", "error: no slides available");
			}
			this.initWebGL(this.container);
		}
		, initWebGL: function (container) {
			var _gthis = this;
			if (container == null) {
				console.log("src/ViewMaster.hx:100:", "container don't exist");
				return;
			}
			container.innerHTML = "";
			container.appendChild(this.canvas);
			var csize = container.getBoundingClientRect();
			this.canvas.width = csize.width | 0;
			this.canvas.height = csize.height | 0;
			this.pxcontainer.start("auto", container, this.canvas);
			this.slideContainer = new PIXI.Container();
			this.wordmarkContainer = new PIXI.Container();
			this.pxcontainer.stage.addChild(this.slideContainer);
			var _g = 0;
			var _g1 = this.slides;
			while (_g < _g1.length) {
				var slide = _g1[_g];
				++_g;
				slide.init(this.slideContainer, this.config.transitionIn, this.config.idle, this.config.transitionOut);
				if (this.config.motionBlur != null) {
					slide.blurpower = this.config.motionBlur;
				}
				this.wordmarkContainer.addChild(slide.wordmark);
			}
			this.slides[0].wordmark.visible = true;
			this.shadowOverlay = PIXI.Sprite.fromImage(this.config.overlay);
			this.colorMatrixLogo = new PIXI.filters.ColorMatrixFilter();
			this.colorMatrixLogo.hue(0, true);
			// console.log("src/ViewMaster.hx:133:","precision mediump float;\r\n\r\nvarying vec2 vTextureCoord;\r\n\r\nuniform sampler2D uSampler;\r\nuniform vec4 filterArea;\r\nuniform vec2 red;\r\nuniform vec2 green;\r\nuniform vec2 blue;\r\n\r\nvoid main(void)\r\n{\r\n   gl_FragColor.r = texture2D(uSampler, vTextureCoord + red/filterArea.xy).r;\r\n   gl_FragColor.g = texture2D(uSampler, vTextureCoord + green/filterArea.xy).g;\r\n   gl_FragColor.b = texture2D(uSampler, vTextureCoord + blue/filterArea.xy).b;\r\n   gl_FragColor.a = texture2D(uSampler, vTextureCoord).a;\r\n}");
			var rgbsplitfilter = new PIXI.Filter(null, "precision mediump float;\r\n\r\nvarying vec2 vTextureCoord;\r\n\r\nuniform sampler2D uSampler;\r\nuniform vec4 filterArea;\r\nuniform vec2 red;\r\nuniform vec2 green;\r\nuniform vec2 blue;\r\n\r\nvoid main(void)\r\n{\r\n   gl_FragColor.r = texture2D(uSampler, vTextureCoord + red/filterArea.xy).r;\r\n   gl_FragColor.g = texture2D(uSampler, vTextureCoord + green/filterArea.xy).g;\r\n   gl_FragColor.b = texture2D(uSampler, vTextureCoord + blue/filterArea.xy).b;\r\n   gl_FragColor.a = texture2D(uSampler, vTextureCoord).a;\r\n}", { red: { type: "vec2", value: [-1, 0] }, green: { type: "vec2", value: [1, -1] }, blue: { type: "vec2", value: [4, -3] } });
			var noiseFilter = new PIXI.filters.NoiseFilter(0.05);
			var clmFilter = new PIXI.filters.ColorMatrixFilter();
			clmFilter.polaroid(true);
			this.slideContainer.filters = [clmFilter, rgbsplitfilter];
			this.pxcontainer.stage.filters = [noiseFilter];
			this.pxcontainer.stage.addChild(this.wordmarkContainer);
			this.shadowOverlay.anchor.set(0.5, 0.5);
			this.pxcontainer.stage.addChild(this.shadowOverlay);
			if (this.onInit != null) {
				this.onInit();
			}
			window.addEventListener("resize", function () {
				var csize1 = container.getBoundingClientRect();
				_gthis.resize(csize1.width | 0, csize1.height | 0);
			});
			var csize2 = container.getBoundingClientRect();
			this.resize(csize2.width | 0, csize2.height | 0);
			this.cycler = new lib_Cycler((this.config.idle + this.config.transitionIn + this.config.transitionOut) * 1000);
			this.cycler.maxState = this.slides.length;
			this.cycler.fullCircle = function (n) {
			};
			this.debounceSlideChange();
			this.updTimer = new haxe_Timer(50);
			this.updTimer.run = function () {
				_gthis.update(50);
				var tmp = Math.floor(Math.random() * 100);
				noiseFilter.seed = tmp / 100;
			};
			window.document.addEventListener("keydown", function (e) {
				if (e.keyCode == 37) {
					_gthis.slideStep(-1, _gthis.config.transitionOut);
				} else if (e.keyCode == 39) {
					_gthis.slideStep(1, _gthis.config.transitionOut);
				}
			});
		}
		, debounceSlideChange: function () {
			this.slideChangeDelay = (this.config.idle + this.config.transitionIn + this.config.transitionOut) * 1000;
		}
		, slideStep: function (direction, delay) {
			var _gthis = this;
			var nextPos = this.slides.indexOf(this.currentSlide) + direction;
			if (nextPos >= this.slides.length) {
				nextPos = 0;
			}
			if (nextPos < 0) {
				nextPos = this.slides.length - 1;
			}
			var tmp = this.cycler.max;
			var tmp1 = Math.floor(delay * 1000);
			this.cycler.position = tmp - tmp1;
			// console.log("src/ViewMaster.hx:226:", this.cycler.position);
			this.autoChange = false;
			haxe_Timer.delay(function () {
				_gthis.autoChange = true;
			}, Math.floor(this.cycler.max));
			haxe_Timer.delay(function () {
				_gthis.slideChange(nextPos);
				_gthis.cycler.runState = nextPos;
			}, Math.floor(delay * 1000) + 1);
			this.debounceSlideChange();
			this.slideChangeDelay += delay * 1000;
		}
		, slideChange: function (n) {
			// console.log("src/ViewMaster.hx:246:", "slide change " + n);
			this.currentSlide = this.slides[n];
			var _g = 0;
			var _g1 = this.slides;
			while (_g < _g1.length) _g1[_g++].wordmark.visible = false;
			this.currentSlide.wordmark.visible = true;
		}
		, redirectToCurProject: function () {
			this.slideStep(-1, this.config.transitionOut);
			var url = this.currentSlide.config.url;
			var title = this.currentSlide.config.title;
			var state = { 'entry_point': title };
			var container = this.pxcontainer;

			haxe_Timer.delay(function () {
				history.pushState(state, title, url);
				window.document.location.assign(url);
				container.app.destroy(true);
				container = null;
			}, Math.floor(this.config.transitionOut * 900));
		}
		, takedown: function () {
			this.pxcontainer.app.destroy(true);
			this.pxcontainer = null;
		}
		, animUpdate: function (t) {
			var _g = 0;
			var _g1 = this.slides.length;
			while (_g < _g1) {
				var n = _g++;
				if (n == this.cycler.runState) {
					this.slides[n].show();
					this.slides[n].animate(this.cycler.cycle);
				} else {
					this.slides[n].hide();
				}
			}
		}
		, update: function (t) {
			if (t == null) {
				t = 16;
			}
			this.cycler.tick(t);
			this.animUpdate(t);
			if (this.slideChangeDelay - t <= 0) {
				this.slideStep(1, 0);
			} else {
				this.slideChangeDelay -= t;
			}
		}
		, resize: function (w, h) {
			this.canvas.width = w;
			this.canvas.height = h;
			this.pxcontainer.renderer.resize(w, h);
			this.pxcontainer.width = w;
			this.pxcontainer.height = h;
			this.slideContainer.position.set(w / 2, h / 2);
			this.shadowOverlay.position.set(w / 2, h / 2);
			this.wordmarkContainer.position.set(w / 2, h / 2);
			if (w < h) {
				this.shadowOverlay.visible = false;
			} else {
				this.shadowOverlay.visible = true;
			}
			this.shadowOverlay.width = w * 1.5;
			this.shadowOverlay.height = h;
			var _g = 0;
			var _g1 = this.slides;
			while (_g < _g1.length) _g1[_g++].updateSize(w, h);
		}
	};
	var SceneSlide = function (conf, appconfig) {
		this.beta = Math.PI / 4;
		this.blurpower = 5;
		this.appconfig = appconfig;
		this.config = conf;
		if (this.config.wordmark_aspect_ratio == null) {
			this.config.wordmark_aspect_ratio = 4;
		}
		this.mobile = false;
	};
	SceneSlide.__name__ = true;
	SceneSlide.prototype = {
		init: function (parent, t1, idle, t2) {
			this.parent = parent;
			this.prerenderer = new PreRender(this.appconfig.slide_mask, 768);
			var pictureTex = PIXI.Texture.fromCanvas(this.prerenderer.canv);
			this.prerenderer.onReady = function () {
				pictureTex.update();
			};
			this.prerenderer.load(this.config.picture);
			this.spriteA = new PIXI.Sprite(pictureTex);
			this.spriteB = new PIXI.Sprite(pictureTex);
			this.wordmark = PIXI.Sprite.fromImage(this.config.wordmark);
			this.wordmark.anchor.set(0.5, 0.5);
			this.wordmark.visible = false;
			this.maskA = PIXI.Sprite.fromImage(this.appconfig.lens_mask);
			this.maskB = PIXI.Sprite.fromImage(this.appconfig.lens_mask);
			var sprite = this.spriteA;
			sprite.anchor.set(0.5, 0.5);
			sprite.blendMode = PIXI.BLEND_MODES.SCREEN;
			var sprite1 = this.spriteB;
			sprite1.anchor.set(0.5, 0.5);
			sprite1.blendMode = PIXI.BLEND_MODES.SCREEN;
			this.maskA.anchor.set(0.5, 0.25);
			this.maskB.anchor.set(0.5, 0.25);
			this.blurFilter = new PIXI.filters.BlurYFilter();
			parent.addChild(this.spriteA);
			parent.addChild(this.maskA);
			parent.addChild(this.spriteB);
			parent.addChild(this.maskB);
			this.spriteA.mask = this.maskA;
			this.spriteB.mask = this.maskB;
			this.spriteA.filters = [this.blurFilter];
			this.spriteB.filters = [this.blurFilter];
			this.initRotA = (Math.random() - 0.5) * Math.PI / 60;
			this.initRotB = (Math.random() - 0.5) * Math.PI / 70;
			if (Math.random() > 0.6) {
				this.initRotA = 0;
			}
			if (Math.random() > 0.6) {
				this.initRotB = 0;
			}
			this.lockMargin = 0.06 + Math.random() * 0.05;
			var totalTime = t1 + idle + t2;
			this.transitionIn = t1 / totalTime;
			this.idleTime = idle / totalTime;
			this.transitionOut = t2 / totalTime;
			// console.log("src/ViewMaster.hx:433:", " " + t1 + ", " + idle + ", " + t2 + ", - " + totalTime + ", " + this.transitionIn + ", " + this.idleTime + ", " + this.transitionOut);
		}
		, animate: function (cycle) {
			if (cycle <= this.transitionIn) {
				this.subCycle = 1 - cycle / this.transitionIn;
				var rate = this.subCycle;
				var values = [0.14, 0, 1.4, 1];
				var tmp;
				if (values.length < 2) {
					throw new js__$Boot_HaxeError("points length must be more than 2");
				} else if (values.length == 2) {
					tmp = values[0] * (1 - rate) + values[1] * rate;
				} else if (values.length == 3) {
					var control = values[1];
					tmp = (values[0] * (1 - rate) + control * rate) * (1 - rate) + (control * (1 - rate) + values[2] * rate) * rate;
				} else {
					tmp = tweenxcore_FloatTools._bezier(rate, values);
				}
				this.subCycle2 = tmp;
				// console.log("src/ViewMaster.hx:447:", this.subCycle);
				this.maskB.rotation = Math.PI;
				this.maskA.position.x = this.initPosA.x;
				this.maskB.position.x = this.initPosB.x;
				var t = this.subCycle;
				var rate1 = -t * (t - 2);
				this.maskA.position.y = this.initPosA.y * (1 - rate1) + this.hidePosA.y * rate1;
				var t1 = this.subCycle;
				var rate2 = -t1 * (t1 - 2);
				this.maskB.position.y = this.initPosB.y * (1 - rate2) + this.hidePosB.y * rate2;
				var rate3 = this.subCycle2;
				this.spriteA.position.x = this.initPosA.x * (1 - rate3) + (this.initPosA.x - 5) * rate3;
				var rate4 = this.subCycle2;
				this.spriteA.position.y = this.initPosA.y * (1 - rate4) + this.hidePosA.y / 100 * rate4;
				var rate5 = this.subCycle2;
				this.spriteB.position.x = this.initPosB.x * (1 - rate5) + (this.initPosB.x + 5) * rate5;
				var rate6 = this.subCycle2;
				this.spriteB.position.y = this.initPosB.y * (1 - rate6) + this.hidePosB.y / 100 * rate6;
				this.spriteA.rotation = this.initRotA;
				this.spriteB.rotation = this.initRotB;
				this.blurFilter.blur = 0;
				this.spriteA.alpha = 1 - this.subCycle;
				this.spriteB.alpha = 1 - this.subCycle;
			} else if (cycle > this.transitionIn && cycle <= this.transitionIn + this.idleTime) {
				this.spriteA.position.set(this.initPosA.x, this.initPosA.y);
				this.spriteB.position.set(this.initPosB.x, this.initPosB.y);
				this.spriteA.rotation = this.initRotA;
				this.spriteB.rotation = this.initRotB;
				this.blurFilter.blur = 0;
			} else if (cycle > this.transitionIn + this.idleTime) {
				var from = this.transitionIn + this.idleTime;
				var rate7 = (cycle - from) / (1 - from);
				var values1 = [0.08, -0.24, 0.38, 0.38, 1.36, 0.7, 1.1, 1];
				var tmp1;
				if (values1.length < 2) {
					throw new js__$Boot_HaxeError("points length must be more than 2");
				} else if (values1.length == 2) {
					tmp1 = values1[0] * (1 - rate7) + values1[1] * rate7;
				} else if (values1.length == 3) {
					var control1 = values1[1];
					tmp1 = (values1[0] * (1 - rate7) + control1 * rate7) * (1 - rate7) + (control1 * (1 - rate7) + values1[2] * rate7) * rate7;
				} else {
					tmp1 = tweenxcore_FloatTools._bezier(rate7, values1);
				}
				this.subCycle = tmp1;
				var from1 = this.transitionIn + this.idleTime;
				this.subCycle2 = (cycle - from1) / (1 - from1);
				var t2 = this.subCycle2;
				var rate8 = -t2 * (t2 - 2);
				this.maskA.position.y = this.initPosA.y * (1 - rate8) + this.hidePosA.y * rate8;
				var t3 = this.subCycle2;
				var rate9 = -t3 * (t3 - 2);
				this.maskB.position.y = this.initPosB.y * (1 - rate9) + this.hidePosB.y * rate9;
				var t4 = this.subCycle;
				var rate10 = t4 < -1 || 1 < t4 ? 0 : 1 - Math.sqrt(1 - t4 * t4);
				this.spriteA.position.x = this.initPosA.x * (1 - rate10) + this.hidePosA.x * rate10;
				var t5 = this.subCycle;
				var rate11 = t5 == 0 ? 0 : t5 == 1 ? 1 : Math.sin(t5 * 1.57079632679489656);
				this.spriteA.position.y = this.initPosA.y * (1 - rate11) + this.hidePosA.y * rate11;
				var t6 = this.subCycle;
				var rate12 = t6 < -1 || 1 < t6 ? 0 : 1 - Math.sqrt(1 - t6 * t6);
				this.spriteB.position.x = this.initPosB.x * (1 - rate12) + this.hidePosB.x * rate12;
				var t7 = this.subCycle;
				var rate13 = --t7 * (t7 *= t7) * t7 + 1;
				this.spriteB.position.y = this.initPosB.y * (1 - rate13) + this.hidePosB.y * rate13;
				var rate14 = this.subCycle;
				this.spriteA.rotation = this.initRotA * (1 - rate14) + this.beta * rate14;
				var rate15 = this.subCycle;
				this.spriteB.rotation = this.initRotB * (1 - rate15) + this.beta * rate15;
				var rate16 = this.subCycle;
				this.blurFilter.blur = 0 * (1 - rate16) + this.blurpower * rate16;
			}
			if (cycle > 0 && cycle < this.transitionIn + this.lockMargin) {
				var t8 = cycle / (this.transitionIn + this.lockMargin);
				this.subCycle2 = t8 == 0 ? 0 : t8 == 1 ? 1 : (t8 *= 2) < 1 ? 0.5 * (t8 * t8 * (3.5949095 * t8 - 2.5949095)) : 0.5 * ((t8 -= 2) * t8 * (3.5949095 * t8 + 2.5949095) + 2);
				var t9 = this.subCycle2;
				var rate17 = t9 * t9;
				this.spriteA.rotation = 0 * (1 - rate17) + this.initRotA * rate17;
				var t10 = this.subCycle2;
				var rate18 = -t10 * (t10 - 2);
				this.spriteB.rotation = 0 * (1 - rate18) + this.initRotB * rate18;
				if (cycle > this.transitionIn - this.lockMargin) {
					var from2 = this.transitionIn - this.lockMargin;
					this.subCycle = (cycle - from2) / (this.transitionIn + this.lockMargin - from2);
					var t11 = this.subCycle;
					var rate19 = -t11 * (t11 - 2);
					this.spriteA.position.y = (this.initPosA.y + 20) * (1 - rate19) + this.initPosA.y * rate19;
					var t12 = this.subCycle;
					var rate20 = t12 * t12;
					this.spriteB.position.y = (this.initPosB.y - 20 * this.lockMargin) * (1 - rate20) + this.initPosB.y * rate20;
				}
			}
		}
		, updateSize: function (w, h) {
			this.slidesize = Math.min(w, h) * 0.75;
			if (w < h) {
				this.slidesize = h * 1.2;
				this.initPosA = { x: 0, y: this.slidesize * 0.02 };
				this.initPosB = { x: 0, y: this.slidesize * 0.02 };
				this.hidePosA = { y: -this.slidesize, x: 0 };
				this.hidePosB = { x: 0, y: this.slidesize };
				this.mobile = true;
				this.wordmark.width = w * 0.95;
				this.wordmark.height = Math.floor(this.wordmark.width / this.config.wordmark_aspect_ratio);
			} else {
				this.initPosA = { y: 0, x: -this.slidesize * 0.47 };
				this.initPosB = { x: this.slidesize * 0.47, y: 0 };
				this.hidePosA = { y: -this.slidesize * 1.3, x: this.slidesize * 1.5 };
				this.hidePosB = { y: this.slidesize * 1.3, x: -this.slidesize * 1.5 };
				this.mobile = false;
				this.wordmark.width = w * 0.6;
				this.wordmark.height = Math.floor(this.wordmark.width / this.config.wordmark_aspect_ratio);
			}
			var spr = this.spriteA;
			spr.width = spr.height = this.slidesize;
			var spr1 = this.spriteB;
			spr1.width = spr1.height = this.slidesize;
			var mask = this.maskA;
			mask.width = this.slidesize * 1.2;
			mask.height = mask.width * 2;
			var mask1 = this.maskB;
			mask1.width = this.slidesize * 1.2;
			mask1.height = mask1.width * 2;
		}
		, hide: function () {
			this.spriteA.visible = false;
			this.spriteB.visible = false;
		}
		, show: function () {
			this.spriteA.visible = true;
			this.spriteB.visible = true;
		}
	};
	var haxe_Timer = function (time_ms) {
		var me = this;
		this.id = setInterval(function () {
			me.run();
		}, time_ms);
	};
	haxe_Timer.__name__ = true;
	haxe_Timer.delay = function (f, time_ms) {
		var t = new haxe_Timer(time_ms);
		t.run = function () {
			t.stop();
			f();
		};
		return t;
	};
	haxe_Timer.prototype = {
		stop: function () {
			if (this.id == null) {
				return;
			}
			clearInterval(this.id);
			this.id = null;
		}
		, run: function () {
		}
	};
	var haxe_http_HttpBase = function (url) {
		this.url = url;
		this.headers = [];
		this.params = [];
		this.emptyOnData = $bind(this, this.onData);
	};
	haxe_http_HttpBase.__name__ = true;
	haxe_http_HttpBase.prototype = {
		onData: function (data) {
		}
		, onBytes: function (data) {
		}
		, onError: function (msg) {
		}
		, onStatus: function (status) {
		}
		, hasOnData: function () {
			return !Reflect.compareMethods($bind(this, this.onData), this.emptyOnData);
		}
		, success: function (data) {
			this.responseBytes = data;
			this.responseAsString = null;
			if (this.hasOnData()) {
				this.onData(this.get_responseData());
			}
			this.onBytes(this.responseBytes);
		}
		, get_responseData: function () {
			if (this.responseAsString == null && this.responseBytes != null) {
				this.responseAsString = this.responseBytes.getString(0, this.responseBytes.length, haxe_io_Encoding.UTF8);
			}
			return this.responseAsString;
		}
	};
	var haxe_http_HttpJs = function (url) {
		this.async = true;
		this.withCredentials = false;
		haxe_http_HttpBase.call(this, url);
	};
	haxe_http_HttpJs.__name__ = true;
	haxe_http_HttpJs.__super__ = haxe_http_HttpBase;
	haxe_http_HttpJs.prototype = $extend(haxe_http_HttpBase.prototype, {
		request: function (post) {
			var _gthis = this;
			this.responseAsString = null;
			this.responseBytes = null;
			var r = this.req = js_Browser.createXMLHttpRequest();
			var onreadystatechange = function (_) {
				if (r.readyState != 4) {
					return;
				}
				var s;
				try {
					s = r.status;
				} catch (e) {
					s = null;
				}
				if (s == 0 && typeof (window) != "undefined") {
					var protocol = window.location.protocol.toLowerCase();
					if (new EReg("^(?:about|app|app-storage|.+-extension|file|res|widget):$", "").match(protocol)) {
						s = r.response != null ? 200 : 404;
					}
				}
				if (s == undefined) {
					s = null;
				}
				if (s != null) {
					_gthis.onStatus(s);
				}
				if (s != null && s >= 200 && s < 400) {
					_gthis.req = null;
					var onreadystatechange1 = haxe_io_Bytes.ofData(r.response);
					_gthis.success(onreadystatechange1);
				} else if (s == null) {
					_gthis.req = null;
					_gthis.onError("Failed to connect or resolve host");
				} else if (s == null) {
					_gthis.req = null;
					_gthis.responseBytes = haxe_io_Bytes.ofData(r.response);
					_gthis.onError("Http Error #" + r.status);
				} else {
					switch (s) {
						case 12007:
							_gthis.req = null;
							_gthis.onError("Unknown host");
							break;
						case 12029:
							_gthis.req = null;
							_gthis.onError("Failed to connect to host");
							break;
						default:
							_gthis.req = null;
							_gthis.responseBytes = haxe_io_Bytes.ofData(r.response);
							_gthis.onError("Http Error #" + r.status);
					}
				}
			};
			if (this.async) {
				r.onreadystatechange = onreadystatechange;
			}
			var _g = this.postBytes;
			var _g1 = this.postData;
			var uri = _g1 == null ? _g == null ? null : new Blob([_g.b.bufferValue]) : _g == null ? _g1 : null;
			if (uri != null) {
				post = true;
			} else {
				var _g2 = 0;
				var _g3 = this.params;
				while (_g2 < _g3.length) {
					var p = _g3[_g2];
					++_g2;
					if (uri == null) {
						uri = "";
					} else {
						uri = Std.string(uri) + "&";
					}
					var s1 = p.name;
					var value = Std.string(uri) + encodeURIComponent(s1) + "=";
					var s2 = p.value;
					uri = value + encodeURIComponent(s2);
				}
			}
			try {
				if (post) {
					r.open("POST", this.url, this.async);
				} else if (uri != null) {
					r.open("GET", this.url + (this.url.split("?").length <= 1 ? "?" : "&") + Std.string(uri), this.async);
					uri = null;
				} else {
					r.open("GET", this.url, this.async);
				}
				r.responseType = "arraybuffer";
			} catch (e1) {
				var e2 = ((e1) instanceof js__$Boot_HaxeError) ? e1.val : e1;
				this.req = null;
				this.onError(e2.toString());
				return;
			}
			r.withCredentials = this.withCredentials;
			if (!Lambda.exists(this.headers, function (h) {
				return h.name == "Content-Type";
			}) && post && this.postData == null) {
				r.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
			}
			var _g21 = 0;
			var _g31 = this.headers;
			while (_g21 < _g31.length) {
				var h1 = _g31[_g21];
				++_g21;
				r.setRequestHeader(h1.name, h1.value);
			}
			r.send(uri);
			if (!this.async) {
				onreadystatechange(null);
			}
		}
	});
	var haxe_io_Bytes = function (data) {
		this.length = data.byteLength;
		this.b = new Uint8Array(data);
		this.b.bufferValue = data;
		data.hxBytes = this;
		data.bytes = this.b;
	};
	haxe_io_Bytes.__name__ = true;
	haxe_io_Bytes.ofData = function (b) {
		var hb = b.hxBytes;
		if (hb != null) {
			return hb;
		}
		return new haxe_io_Bytes(b);
	};
	haxe_io_Bytes.prototype = {
		getString: function (pos, len, encoding) {
			if (pos < 0 || len < 0 || pos + len > this.length) {
				throw new js__$Boot_HaxeError(haxe_io_Error.OutsideBounds);
			}
			if (encoding == null) {
				encoding = haxe_io_Encoding.UTF8;
			}
			var s = "";
			var b = this.b;
			var i = pos;
			var max = pos + len;
			switch (encoding._hx_index) {
				case 0:
					while (i < max) {
						var c = b[i++];
						if (c < 128) {
							if (c == 0) {
								break;
							}
							s += String.fromCodePoint(c);
						} else if (c < 224) {
							var code = (c & 63) << 6 | b[i++] & 127;
							s += String.fromCodePoint(code);
						} else if (c < 240) {
							var code1 = (c & 31) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
							s += String.fromCodePoint(code1);
						} else {
							var u = (c & 15) << 18 | (b[i++] & 127) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
							if (u < 65535) {
								s += String.fromCodePoint(u);
							}
						}
					}
					break;
				case 1:
					while (i < max) {
						var c1 = b[i++] | b[i++] << 8;
						s += String.fromCodePoint(c1);
					}
					break;
			}
			return s;
		}
	};
	var haxe_io_Encoding = $hxEnums["haxe.io.Encoding"] = {
		__ename__: true, __constructs__: ["UTF8", "RawNative"]
		, UTF8: { _hx_index: 0, __enum__: "haxe.io.Encoding", toString: $estr }
		, RawNative: { _hx_index: 1, __enum__: "haxe.io.Encoding", toString: $estr }
	};
	var haxe_io_Error = $hxEnums["haxe.io.Error"] = {
		__ename__: true, __constructs__: ["Blocked", "Overflow", "OutsideBounds", "Custom"]
		, Blocked: { _hx_index: 0, __enum__: "haxe.io.Error", toString: $estr }
		, Overflow: { _hx_index: 1, __enum__: "haxe.io.Error", toString: $estr }
		, OutsideBounds: { _hx_index: 2, __enum__: "haxe.io.Error", toString: $estr }
		, Custom: ($_ = function (e) { return { _hx_index: 3, e: e, __enum__: "haxe.io.Error", toString: $estr }; }, $_.__params__ = ["e"], $_)
	};
	var js__$Boot_HaxeError = function (val) {
		Error.call(this);
		this.val = val;
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, js__$Boot_HaxeError);
		}
	};
	js__$Boot_HaxeError.__name__ = true;
	js__$Boot_HaxeError.__super__ = Error;
	js__$Boot_HaxeError.prototype = $extend(Error.prototype, {
	});
	var js_Boot = function () { };
	js_Boot.__name__ = true;
	js_Boot.__string_rec = function (o, s) {
		if (o == null) {
			return "null";
		}
		if (s.length >= 5) {
			return "<...>";
		}
		var t = typeof (o);
		if (t == "function" && (o.__name__ || o.__ename__)) {
			t = "object";
		}
		switch (t) {
			case "function":
				return "<function>";
			case "object":
				if (o.__enum__) {
					var e = $hxEnums[o.__enum__];
					var n = e.__constructs__[o._hx_index];
					var con = e[n];
					if (con.__params__) {
						s = s + "\t";
						return n + "(" + ((function ($this) {
							var $r;
							var _g = [];
							{
								var _g1 = 0;
								var _g2 = con.__params__;
								while (true) {
									if (!(_g1 < _g2.length)) {
										break;
									}
									var p = _g2[_g1];
									_g1 = _g1 + 1;
									_g.push(js_Boot.__string_rec(o[p], s));
								}
							}
							$r = _g;
							return $r;
						}(this))).join(",") + ")";
					} else {
						return n;
					}
				}
				if (((o) instanceof Array)) {
					var str = "[";
					s += "\t";
					var _g3 = 0;
					var _g11 = o.length;
					while (_g3 < _g11) {
						var i = _g3++;
						str += (i > 0 ? "," : "") + js_Boot.__string_rec(o[i], s);
					}
					str += "]";
					return str;
				}
				var tostr;
				try {
					tostr = o.toString;
				} catch (e1) {
					var e2 = ((e1) instanceof js__$Boot_HaxeError) ? e1.val : e1;
					return "???";
				}
				if (tostr != null && tostr != Object.toString && typeof (tostr) == "function") {
					var s2 = o.toString();
					if (s2 != "[object Object]") {
						return s2;
					}
				}
				var str1 = "{\n";
				s += "\t";
				var hasp = o.hasOwnProperty != null;
				var k = null;
				for (k in o) {
					if (hasp && !o.hasOwnProperty(k)) {
						continue;
					}
					if (k == "prototype" || k == "__class__" || k == "__super__" || k == "__interfaces__" || k == "__properties__") {
						continue;
					}
					if (str1.length != 2) {
						str1 += ", \n";
					}
					str1 += s + k + " : " + js_Boot.__string_rec(o[k], s);
				}
				s = s.substring(1);
				str1 += "\n" + s + "}";
				return str1;
			case "string":
				return o;
			default:
				return String(o);
		}
	};
	var js_Browser = function () { };
	js_Browser.__name__ = true;
	js_Browser.createXMLHttpRequest = function () {
		if (typeof XMLHttpRequest != "undefined") {
			return new XMLHttpRequest();
		}
		if (typeof ActiveXObject != "undefined") {
			return new ActiveXObject("Microsoft.XMLHTTP");
		}
		throw new js__$Boot_HaxeError("Unable to create XMLHttpRequest object.");
	};
	var lib_Cycler = function (totalTime) {
		this.maxState = 1;
		this.runState = 0;
		this.updTime = 0;
		this.max = 1;
		this.cycle = 0;
		this.position = 0;
		this.max = totalTime;
	};
	lib_Cycler.__name__ = true;
	lib_Cycler.prototype = {
		tick: function (t) {
			if (t != null) {
				this.position += t;
			} else {
				this.position += this.updTime;
			}
			if (this.position > this.max) {
				this.position %= this.max;
				if (this.fullCircle != null) {
					this.fullCircle(this.runState);
				}
			}
			this.cycle = this.position / this.max;
			if (this.fun != null) {
				this.fun(this.cycle);
			}
		}
	};
	var tweenxcore_FloatTools = function () { };
	tweenxcore_FloatTools.__name__ = true;
	tweenxcore_FloatTools._bezier = function (rate, values) {
		if (values.length == 4) {
			var control1 = values[1];
			var control2 = values[2];
			var control = control1 * (1 - rate) + control2 * rate;
			return ((values[0] * (1 - rate) + control1 * rate) * (1 - rate) + control * rate) * (1 - rate) + (control * (1 - rate) + (control2 * (1 - rate) + values[3] * rate) * rate) * rate;
		}
		var _g = [];
		var _g1 = 0;
		var _g2 = values.length - 1;
		while (_g1 < _g2) {
			var i = _g1++;
			_g.push(values[i] * (1 - rate) + values[i + 1] * rate);
		}
		return tweenxcore_FloatTools._bezier(rate, _g);
	};
	function $getIterator(o) { if (o instanceof Array) return HxOverrides.iter(o); else return o.iterator(); }
	function $bind(o, m) { if (m == null) return null; if (m.__id__ == null) m.__id__ = $global.$haxeUID++; var f; if (o.hx__closures__ == null) o.hx__closures__ = {}; else f = o.hx__closures__[m.__id__]; if (f == null) { f = m.bind(o); o.hx__closures__[m.__id__] = f; } return f; }
	$global.$haxeUID |= 0;
	if (String.fromCodePoint == null) String.fromCodePoint = function (c) { return c < 0x10000 ? String.fromCharCode(c) : String.fromCharCode((c >> 10) + 0xD7C0) + String.fromCharCode((c & 0x3FF) + 0xDC00); }
	String.__name__ = true;
	Array.__name__ = true;
	Date.__name__ = "Date";
	Object.defineProperty(js__$Boot_HaxeError.prototype, "message", {
		get: function () {
			return String(this.val);
		}
	});
	js_Boot.__toStr = ({}).toString;
	Perf.MEASUREMENT_INTERVAL = 1000;
	Perf.FONT_FAMILY = "Helvetica,Arial";
	Perf.FPS_BG_CLR = "#00FF00";
	Perf.FPS_WARN_BG_CLR = "#FF8000";
	Perf.FPS_PROB_BG_CLR = "#FF0000";
	Perf.MS_BG_CLR = "#FFFF00";
	Perf.MEM_BG_CLR = "#086A87";
	Perf.INFO_BG_CLR = "#00FFFF";
	Perf.FPS_TXT_CLR = "#000000";
	Perf.MS_TXT_CLR = "#000000";
	Perf.MEM_TXT_CLR = "#FFFFFF";
	Perf.INFO_TXT_CLR = "#000000";
	Perf.TOP_LEFT = "TL";
	Perf.TOP_RIGHT = "TR";
	Perf.BOTTOM_LEFT = "BL";
	Perf.BOTTOM_RIGHT = "BR";
	Perf.DELAY_TIME = 4000;


})(typeof exports != "undefined" ? exports : typeof window != "undefined" ? window : typeof self != "undefined" ? self : this, typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this);
