/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */
import React from 'react';
import { ViewMaster } from '../../static/viewmasterpixi';

class ViewMasterComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const vmprops = this.props;
    // eslint-disable-next-line no-new
    this.vm = new ViewMaster(vmprops, null, null, 'viewmaster');
  }

  componentWillUnmount() {
    // console.log('Unmounting Viewmaster...');
    // console.log(this.vm);
    this.vm.takedown();
  }

  render() {
    // const style = this.props;
    return <div id="viewmaster" style={{ height: 'calc(100% - 3.5rem)', position: 'absolute', bottom: '0' }} />;
  }
}

export default ViewMasterComponent;
